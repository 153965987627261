
import { defineComponent, PropType, ref } from "vue";
import { applyListClick, store } from "@/utils";
import { ApplyItemProps } from "./type";
import ProcessType from "@/enums/processType";

export default defineComponent({
  name: "TaskItem",
  props: {
    data: {
      type: Object as PropType<ApplyItemProps>,
      required: true,
    },
  },
  setup() {
    const flag = ref(1);

    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };
    const touchend = (item: ApplyItemProps) => {
      if (flag.value === 1) {
        applyListClick(item);
        store.set("taskItem", item);
      }
    };

    return { ProcessType, touchstart, touchmove, touchend };
  },
});
