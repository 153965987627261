
import { defineComponent, ref, PropType, toRefs } from "vue";
import fetchApi from "@/api";
import ProcessType from "@/enums/processType";
import { ApplyItemProps, SearchProps } from "./type";
import ApplyItem from "./ApplyItem.vue";
import { store } from "@/utils";
import { TaskItemProps } from "../type";

export default defineComponent({
  name: "ApplyList",
  components: { ApplyItem },
  props: {
    searchVal: {
      type: Object as PropType<SearchProps>,
      default: () => ({ queryStatusCode: ProcessType.APPROVE }),
    },
  },
  emits: ["change"],
  setup(props) {
    const { searchVal } = toRefs(props);
    const list = ref<ApplyItemProps[]>([]);
    const pageIndex = ref(1);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);

    const onLoad = () => {
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
        pageIndex.value = 1;
      }
      const params = {
        pageNo: pageIndex.value,
        pageSize: 20,
        ...searchVal.value,
      };
      if (searchVal.value.queryStatusCode === "1") {
        params.queryStatusCode = ["1", "2", "6", "8"] as unknown as ProcessType;
      }
      return fetchApi
        .getApplyList()
        .data(params)
        .send("POST")
        .then((res) => {
          if (res.data) {
            const data = JSON.parse(res.data);
            console.log(data);
            list.value = [...list.value, ...data.result];
            if (
              data.totalCount <= list.value.length ||
              data.result.length === 0
            ) {
              finished.value = true;
            }
          }
          pageIndex.value++;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      // 清空列表数据
      finished.value = false;
      refreshing.value = true;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    // 处理外置表单审批后跳回刷新列表逻辑
    const afterApprove = () => {
      const currentTask = store.get("taskItem");
      const { queryStatusCode } = searchVal.value;
      if (currentTask) {
        const { reqId } = currentTask as unknown as TaskItemProps;
        fetchApi
          .getApplyList()
          .data({
            processInstanceIds: reqId, // 多个用逗号分隔
            pageSize: 1,
            pageNo: 1,
          })
          .send("POST")
          .then((res) => {
            store.clear("taskItem");
            const data = JSON.parse(res.data);
            const index = list.value.findIndex((item) => item.reqId === reqId);
            if (index > -1) {
              if (data.result && data.result.length > 0) {
                const [node] = data.result;
                if (queryStatusCode) {
                  if (
                    node.statusCode !== queryStatusCode &&
                    node.statusCode !== "3"
                  ) {
                    list.value.splice(index, 1);
                    console.log("call jdme setStorage reqId");
                    window.jme.storage.setStorage({ key: "reqID", value: "" });
                  }
                } else {
                  list.value.splice(index, 1, {
                    ...node,
                    statusCode: node.statusCode,
                  });
                }
              } else {
                list.value.splice(index, 1);
                console.log("call jdme setStorage reqId");
                window.jme.storage.setStorage({ key: "reqID", value: "" });
              }
            }
          });
      }
    };

    return {
      list,
      loading,
      finished,
      refreshing,
      onLoad,
      onRefresh,
      afterApprove,
    };
  },
});
