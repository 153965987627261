import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-396ad28e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "apply-wrapper-search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TaskFilter = _resolveComponent("TaskFilter")!
  const _component_van_search = _resolveComponent("van-search")!
  const _component_ApplyList = _resolveComponent("ApplyList")!
  const _component_SearchList = _resolveComponent("SearchList")!

  return (_openBlock(), _createElementBlock("div", {
    class: "apply-wrapper",
    style: _normalizeStyle({
      paddingTop: _ctx.paddingTop + 'px',
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader, {
        title: "我的申请",
        isFixed: false
      }),
      _createVNode(_component_van_search, {
        placeholder: "搜索",
        "show-action": "",
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearch = true))
      }, {
        action: _withCtx(() => [
          _createVNode(_component_TaskFilter, {
            ref: "taskFilter",
            onFilter: _ctx.onFilter
          }, null, 8, ["onFilter"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "apply-pull-refresh",
      style: _normalizeStyle({
        display: 'block',
        height: `calc(100vh - ${_ctx.paddingTop + 'px'})`,
      })
    }, [
      _createVNode(_component_ApplyList, {
        ref: "applyVm",
        searchVal: _ctx.searchVal
      }, null, 8, ["searchVal"])
    ], 4),
    (_ctx.showSearch)
      ? (_openBlock(), _createBlock(_component_SearchList, {
          key: 0,
          class: "searchBox",
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSearch = false)),
          style: _normalizeStyle({
        height: `calc(100vh - ${_ctx.paddingTop - 54 + 'px'})`,
        top: _ctx.paddingTop - 54 + 'px',
      })
        }, null, 8, ["style"]))
      : _createCommentVNode("", true)
  ], 4))
}