
import { defineComponent, ref, nextTick, onMounted, onUnmounted } from "vue";
import TaskFilter from "@/components/TaskFIlter.vue";
import SearchList from "./SearchList.vue";
import ApplyList from "./ApplyList.vue";
import { SearchProps } from "./type";
import ProcessType from "@/enums/processType";
import PageHeader from "@/components/PageHeader.vue";
import { eventBus, getDeviceSomeInfo } from "@/utils";

export default defineComponent({
  name: "Template",
  components: {
    SearchList,
    ApplyList,
    TaskFilter,
    PageHeader,
  },
  props: {},
  setup() {
    const { safeTop } = getDeviceSomeInfo();
    const paddingTop = Math.max(safeTop - 46, 0) + 44 + 54;
    const showSearch = ref(false);
    const applyVm = ref<typeof ApplyList>();
    const taskFilter = ref<typeof TaskFilter>();
    // TODO:改造成v-model
    const searchVal = ref<SearchProps>({
      queryStartDate: "",
      queryEndDate: "",
      queryStatusCode: ProcessType.APPROVE,
    });

    onMounted(() => {
      eventBus.on("judgeApply", () => {
        setTimeout(() => {
          applyVm.value?.afterApprove();
        }, 500);
      });
      eventBus.on("resetApplyList", () => {
        showSearch.value = false;
        taskFilter.value?.onReset();
        taskFilter.value?.onFilter();
      });
    });
    onUnmounted(() => {
      eventBus.off("judgeApply");
      eventBus.off("resetApplyList");
    });

    const onFilter = (val: SearchProps) => {
      searchVal.value = val;
      nextTick(() => {
        applyVm.value?.onRefresh();
      });
    };
    return {
      applyVm,
      taskFilter,
      showSearch,
      searchVal,
      onFilter,
      paddingTop,
    };
  },
});
