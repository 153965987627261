import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e19a35a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "apply-item",
  style: {"display":"flex"}
}
const _hoisted_2 = { class: "process-comps-taskItem-title" }
const _hoisted_3 = { class: "apply-item-status" }
const _hoisted_4 = {
  key: 0,
  style: {"color":"var(--van-danger-color)"}
}
const _hoisted_5 = {
  key: 1,
  style: {"color":"var(--van-success-color)"}
}
const _hoisted_6 = {
  key: 2,
  style: {"color":"var(--van-picker-cancel-action-color)"}
}
const _hoisted_7 = {
  key: 3,
  style: {"color":"var(--van-picker-cancel-action-color)"}
}
const _hoisted_8 = {
  key: 4,
  style: {"color":"var(--primary-color)"}
}
const _hoisted_9 = { class: "process-comps-taskItem-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", {
    clstag: "h|keycount|approve_1648190968616|5",
    class: "process-comps-taskItem-content",
    onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.touchstart && _ctx.touchstart(...args))),
    onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args))),
    onTouchend: _cache[2] || (_cache[2] = () => _ctx.touchend(_ctx.data))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.reqName), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.data.statusCode === _ctx.ProcessType.REJECT)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_van_icon, {
                class: "apply-item-status-icon",
                name: "clear"
              }),
              _createTextVNode(" 驳回 ")
            ]))
          : (_ctx.data.statusCode === _ctx.ProcessType.COMPLETE)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_van_icon, {
                  class: "apply-item-status-icon",
                  name: "checked"
                }),
                _createTextVNode(" 已完成 ")
              ]))
            : (_ctx.data.statusCode === _ctx.ProcessType.DRAFT)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_van_icon, {
                    class: "apply-item-status-icon",
                    name: "todo-list"
                  }),
                  _createTextVNode(" 草稿 ")
                ]))
              : (_ctx.data.statusCode === _ctx.ProcessType.CANCEL)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_van_icon, {
                      class: "apply-item-status-icon",
                      name: "clear"
                    }),
                    _createTextVNode(" 取消 ")
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_van_icon, {
                      class: "apply-item-status-icon",
                      name: "more"
                    }),
                    _createTextVNode(" 审批中 ")
                  ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("span", null, _toDisplayString(_ctx.data.currentTaskAssigneerName), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.data.reqTime), 1)
    ])
  ], 32))
}