import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40fe552c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"calc(100vh - 54px)"} }
const _hoisted_2 = { class: "process-comps-taskItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_ApplyItem = _resolveComponent("ApplyItem")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createBlock(_component_van_pull_refresh, {
    class: "apply-list",
    modelValue: _ctx.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.refreshing) = $event)),
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Empty)
      ], 512), [
        [_vShow, !_ctx.loading && _ctx.list.length === 0]
      ]),
      _createVNode(_component_van_list, {
        loading: _ctx.loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
        finished: _ctx.finished,
        offset: "100",
        "finished-text": _ctx.list.length === 0 ? '' : '没有更多了',
        onLoad: _ctx.onLoad
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createBlock(_component_van_cell, { key: index }, {
              value: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ApplyItem, { data: item }, null, 8, ["data"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["loading", "finished", "finished-text", "onLoad"])
    ]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]))
}